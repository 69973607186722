.column {
  /* float: left; */
  width: 50%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.form-card-left {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 1vh 1vh 1vh 1vh;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: auto;
  height: 70%;
}

.form-card-right {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 3vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 1vh 1vh 1vh 1vh;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: auto;
  height: 70%;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  margin: auto;
  position: relative;
  word-wrap: break-word;
}

form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  height: 90%;
  margin: auto;
  position: relative;
  overflow: auto;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

input[type="text"] {
  width: 100%;
  padding: 8px 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

select {
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}


input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  background-color: #74aa9c;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

label {
  padding: 6px 6px 6px 0;
  display: inline-block;
}

.animated-templates-fetching-text {
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .row {
    flex-direction: column; /* Stack columns below each other on smaller screens */
  }

  .column {
    width: 100%; /* Take full width on smaller screens */
    margin: 0; /* Remove margin on smaller screens */
  }

  .form-card-left {
    width: 100%;
    margin: 0;
  }
  .form-card-right {
    width: 100%;
    margin: 0;
  }

}

 hr {
        border: 1px solid #000; /* Change color and style as needed */
        margin: 20px 0; /* Adjust spacing as needed */
    }
